<template>
    <header class="header">
      <div class="container">
        <!-- 左侧内容：Logo 和 导航栏 -->
        <div class="left-content">
          <div class="logo">
            <img src="@/assets/256.png" alt="Logo" class="logo-img" />
            <span class="logo-text">播伴侣</span>
          </div>
          <div style="display: inline-block; margin-left: 3vh;font-size: 2vh;">
            <span>|</span>
          </div>
          
          <nav class="nav">
            <router-link exact to="/" class="nav-item">首页</router-link>
            <router-link to="/tutorial" class="nav-item">教程</router-link>
            <router-link to="/live-questions" class="nav-item">直播问题</router-link>
            <router-link to="/obs-download" class="nav-item">OBS下载</router-link>
          </nav>
          
        </div>
  
        <!-- 右侧内容：登录按钮或用户信息 -->
        <div class="right-content" >
          <button v-if="!isLoggedIn" class="btn login-btn" @click="login">登录</button>
          <div v-else class="user-info"  >
            <button class="btn recharge-btn" @click="getvip">充值</button>
            <div class="user-details">
              <span class="user-name">{{ user_info.nickname }}</span>
              <img :src="user_info.headimgurl" alt="User Avatar" class="user-avatar" />
            </div>
          </div>
        </div>
        <!-- 半透明遮罩层 -->
        <div v-if="showLoginOptions" class="overlay" @click="toggleLoginOptions"></div>

        <!-- 中央的登录选项框 -->
    <div v-if="showLoginOptions" class="login-options">
      <button class="close-btn" @click="toggleLoginOptions">✕</button>
      <h2>请选择登录方式</h2>
      <button @click="wechatLogin">微信登录</button>
      <button @click="qqLogin">QQ登录</button>
    </div>
        <!-- 充值弹窗 -->
        <div v-if="showRechargePopup" class="recharge-popup">
        <div class="recharge-content">
          <button class="close-btn" @click="showRechargePopup = false">X</button>
          <h2 class="title">会员升级</h2>
          <div class="membership-options">
            <div class="option" :class="{ active: selectedOption === 'SVIP' }" @click="selectOption('SVIP')">
              <span>SVIP</span>
            </div>
            <div class="option" :class="{ active: selectedOption === 'VIP' }" @click="selectOption('VIP')">
              <span>VIP</span>
            </div>
          </div>
          <div class="pricing">
            <div
              class="price-option"
              v-for="plan in plans[selectedOption].pricelist"
              :key="plan.duration"
              :class="{ selected: selectedPlan === plan }"
              @click="selectPlan(plan)"
            >
              <p class="duration">{{ plan.time }}</p>
              <p class="price">¥{{ plan.price_z }}</p>
              <p class="original-price">¥{{ plan.price }}月</p>
            </div>
          </div>
          <div class="payment">
            <div class="payment-methods">
              <span @click="selectPaymentMethod('wechat')" :class="['payment-method', paymentMethod === 'wechat' ? 'payment-method-active' : '']">微信支付</span>
              <span @click="selectPaymentMethod('alipay')" :class="['payment-method', paymentMethod === 'alipay' ? 'payment-method-active' : '']">支付宝支付</span>
            </div>
            <div class="qr-section">
              <QrCodeComponent :qrcodeUrl="qrcodeUrl" class="qr-code"/>
              <!-- <img :src="selectedQRCode" alt="支付二维码" class="qr-code" /> -->
              <div class="agreement">
                <p class="payment-info">
                ¥{{ selectedPlan ? selectedPlan.price : '0' }}
                请先阅读下方协议后，使用支付宝或微信扫码完成支付
              </p>
            <span>
              已阅读并同意
              <a href="#" target="_blank">《主播窗助手付费协议》</a> <a href="#" target="_blank">《自动续费协议》</a>
            </span>
        </div>
            </div>
          </div>
          
    </div>
  </div>
      </div>
    </header>
  </template>
  
  <script>
  import axios from 'axios';
  import QrCodeComponent from './QrCodeComponent.vue'  // 导入二维码组件
  export default {
    data() {
      return {
        qrcodeUrl: 'https://qr.alipay.com/bax03629ngnn8v7qj26n3049',
        showLoginOptions:false,
        isLoggedIn: false, // 默认未登录
        user_info: null,
      paymentMethod:'wechat',
      showRechargePopup: false,
      selectedOption: 'SVIP',
      selectedPlan: null,
      selectedQRCode: 'path/to/wechat-qr.png',
      agreementAccepted: false,
      plans: {
        SVIP: null,
        VIP: null,
      },
      };
    },
    components: {
      QrCodeComponent
   },
    methods: {
    async getprice(){
      try {
        const response = await axios.get("https://bobanlv.com/sucai/price/index");
        console.log('价格数据:', response.data.objects);
        return response.data.objects; // 返回数据
    } catch (error) {
        console.error('请求失败:', error);
        return []; // 发生错误时返回空数组，避免 undefined
    }
    },
    // 处理从后端获取的数据并更新 plans 对象
  async updatePlansWithFetchedData() {
    try {
        const plansData = await this.getprice();
        console.log(plansData)
        
        for (const plan of plansData) {
            // 根据 title 字段的值更新 plans 对象
            // 假设每个 plan 对象都有一个 title 字段和一个 pricelist 字段
            const { title, pricelist } = plan;
            
            // 将数据添加到 plans 对象的相应属性中
            // 如果该属性不存在，则先创建一个空对象
            if (!this.plans[title]) {
              this.plans[title] = {};
            }
            this.plans[title].pricelist = pricelist;
        }
        
        console.log('plans 对象已更新:', this.plans);
    } catch (error) {
        console.error('更新 plans 对象时发生错误:', error);
        // 处理错误，例如显示错误消息给用户
    }
},
    getvip() {
        this.showRechargePopup = true;
        // 设置默认选中的计划为当前选项的第一个
        const defaultPlan = this.plans[this.selectedOption].pricelist["time_1"]
        if (defaultPlan) {
          this.selectedPlan = defaultPlan
        }
      },
      login() {
        this.showLoginOptions = !this.showLoginOptions; // 切换显示状态
        // this.showRechargePopup = !this.showRechargePopup
        // const defaultPlan = this.plans[this.selectedOption].pricelist["time_1"]
        // if (defaultPlan) {
        //   this.selectedPlan = defaultPlan
        // }

      },
      wechatLogin() {
        this.showLoginOptions = false; // 关闭弹窗
        const wechatAuthUrl = 'http://39.103.61.113/user/weblogin/wechatloginweb';
        window.location.href = wechatAuthUrl;
        
    },
    qqLogin() {
      this.showLoginOptions = false; // 关闭弹窗
      const wechatAuthUrl = 'http://39.103.61.113/user/weblogin/qqloginweb';
      window.location.href = wechatAuthUrl;
      
    },
    toggleLoginOptions() {
      this.showLoginOptions = !this.showLoginOptions; // 切换显示状态
    },
    // 模拟用户信息登录
    fetchUserInfo() {
      fetch('https://bobanlv.com/weblogin/get_wechatuser_msg', {
        credentials: 'include' // 允许跨域携带 cookies
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.user_info) {
            this.isLoggedIn = true
            this.user_info = data.user_info; // 更新用户信息
            console.log(this.user_info)
          } else {
            this.user_info = null; // 如果没有用户信息，置空
          }
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
        });
    },
    selectOption(option) {
      this.selectedOption = option;
      // 设置默认选中的计划为当前选项的第一个
      const defaultPlan = this.plans[this.selectedOption] && this.plans[this.selectedOption][0]
      if (defaultPlan) {
        this.selectedPlan = defaultPlan
      }
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    selectPaymentMethod(method) {
      this.paymentMethod = method;
      this.selectedQRCode = method === 'wechat' ? 'path/to/wechat-qr.png' : 'path/to/alipay-qr.png';
    },
    pay() {
      if (!this.agreementAccepted || !this.selectedPlan) return;
      alert(`支付成功: ${this.selectedOption} ${this.selectedPlan.duration}个月`);
      this.showRechargePopup = false;
    },
    },
    mounted() {
    this.fetchUserInfo(); // 页面加载时请求用户数据
    this.updatePlansWithFetchedData()
  }
  };
  </script>
  
  <style scoped>
  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 6.5vh; /* 设定一个适合的高度 */
    color: white;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: transparent; /* 设置背景透明 */
    z-index: 1000; /* 确保 header 在其他内容之上 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 可选：给 header 添加阴影 */
  }
  
  .container {
    display: flex;
    justify-content: space-between; /* 左右两部分 */
    align-items: center;
    width: 85%;
    /* max-width: 1630px; */
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .left-content {
    display: flex;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo-img {
    width: 5vh;
    height: 5vh;
    margin-right: 10px;
  }
  
  .logo-text {
    font-size: 4vh;
    font-weight: bold;
  }
  
  .nav {
    display: flex;
    margin-left: 1.5vh;
  }
  
  .nav-item {
    color: white;
    margin: 0 1.5vh;
    text-decoration: none;
    font-size: 3vh;
  }
  
  .nav-item:hover {
    color: #e6d098;
  }
  
  .nav-item.router-link-active {
  color: #e6d098;
  font-weight: bold; 
  }

  .right-content {
    display: flex;
    align-items: center;
  }
  
  .btn {
    border: 2px solid #e6d098;
    cursor: pointer;
    color: #e6d098;
    background-color: rgba(0, 0, 0, 0.0001);
    
  }
  
  .login-btn {
    padding: 4px 60px;
    font-size: 3vh;
    border-radius: 25px;
    transition: transform 0.3s ease;
  }
  
  .login-btn:hover {
    transform: scale(1.1);
  }
  
  .recharge-btn {
    padding: 0 10px;
    font-size: 14px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-details {
    display: flex;
    align-items: center;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 15px;
  }
  
  .user-name {
    font-size: 14px;

  }
.recharge-button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: #e6d098;
  border: none;
  cursor: pointer;
}
.recharge-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.recharge-content {
  background-color: #444;
  padding: 20px;
  border-radius: 10px;
  width: 700px;
  position: relative;
}

  /* 半透明遮罩层 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* 登录选项框 */
.login-options {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid white;
  border-radius: 8px;
  padding: 40px;
  width: 400px; /* 增大宽度 */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 100;
}

.login-options h2 {
  color: white;
  margin: 0;
}

.login-options button {
  background: transparent;
  color: white;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.login-options button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* 关闭按钮 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.recharge-options {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.recharge-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.recharge-content {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 700px;
  position: relative;
  text-align: center;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border: none;
}
.title {
  font-size: 24px;
  margin-bottom: 10px;
}
.membership-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}
.option {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  width: 40%;
}
.option.active {
  background-color: #ffcc99;
}
.pricing {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}
.price-option {
  background: #333;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100px;
}
.price-option.selected {
  background-color: #e6d098;
}
.duration {
  font-size: 14px;
}
.price {
  font-size: 18px;
  font-weight: bold;
}
.original-price {
  font-size: 12px;
  color: gray;
  text-decoration: line-through;
}
.payment {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0;
}
.payment-methods {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
}
.payment-method {
  color: #e6d098;
  border: 1px solid #e6d098;
  cursor: pointer;
  width: 100px;
}
.payment-method-active{
  color: white;
  background-color: #e6d098;
}
.qr-section {
  display: flex;
  gap: 10px;
}
.qr-code {
  width: 100px;
  height: 100px;
}
.payment-info {
  font-size: 16px;
}
.agreement {
  width: 450px;
  margin-top: 10px;
}

  </style>
  